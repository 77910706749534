export const logInstruction = () => {
  console.clear()
  console.log(`
  ███████╗ ██████╗ ███╗   ██╗ █████╗ ██╗   ██╗███████╗██████╗    ███╗   ███╗███████╗
  ╚══███╔╝██╔═══██╗████╗  ██║██╔══██╗╚██╗ ██╔╝██╔════╝██╔══██╗   ████╗ ████║██╔════╝
    ███╔╝ ██║   ██║██╔██╗ ██║███████║ ╚████╔╝ █████╗  ██║  ██║   ██╔████╔██║█████╗  
   ███╔╝  ██║   ██║██║╚██╗██║██╔══██║  ╚██╔╝  ██╔══╝  ██║  ██║   ██║╚██╔╝██║██╔══╝  
  ███████╗╚██████╔╝██║ ╚████║██║  ██║   ██║   ███████╗██████╔╝██╗██║ ╚═╝ ██║███████╗
  ╚══════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝ ╚═╝╚═╝     ╚═╝╚══════╝
  `)
  console.log(
    '%cযথেষ্ট প্র্যাক্টিস ছাড়া কোড শিখা অসম্ভব!',
    'font-size: 18px; background-color: #f0db4f; color: #323330; font-weight: bolder; padding: 3px 5px; border-radius: 4px'
  )
  console.log(
    '%cএখানে কোড প্র্যাক্টিস করুন',
    'font-size: 28px; color: #f0db4f;'
  )
  console.log(
    '%c- %clet আর const একবার ডিক্লেয়ার করলে পরবর্তিতে আর করতে পারবেন না, সেক্ষেত্রে পেজ রিলোড করে নিন',
    'color: #f0db4f',
    'font-size: 11px; color: #f44336'
  )
  console.log(
    '%cসমস্যা পেলে নিচে কমেন্ট করুন, অথবা আমাকে ইমেইল করুনঃ %ccontact@zonayed.me',
    'font-size: 11px; color: #e7e7e7;',
    'background-color: #333; padding: 2px 5px; font-size: 15px; border-radius: 4'
  )
}
